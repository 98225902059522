exports.components = {
  "component---src-components-blog-single-category-jsx": () => import("./../../../src/components/blog/singleCategory.jsx" /* webpackChunkName: "component---src-components-blog-single-category-jsx" */),
  "component---src-components-blog-single-post-jsx": () => import("./../../../src/components/blog/singlePost.jsx" /* webpackChunkName: "component---src-components-blog-single-post-jsx" */),
  "component---src-components-services-service-single-service-jsx": () => import("./../../../src/components/services/service/singleService.jsx" /* webpackChunkName: "component---src-components-services-service-single-service-jsx" */),
  "component---src-components-team-member-single-team-member-jsx": () => import("./../../../src/components/team/member/singleTeamMember.jsx" /* webpackChunkName: "component---src-components-team-member-single-team-member-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-litigation-jsx": () => import("./../../../src/pages/litigation.jsx" /* webpackChunkName: "component---src-pages-litigation-jsx" */),
  "component---src-pages-newsroom-jsx": () => import("./../../../src/pages/newsroom.jsx" /* webpackChunkName: "component---src-pages-newsroom-jsx" */),
  "component---src-pages-solicitors-jsx": () => import("./../../../src/pages/solicitors.jsx" /* webpackChunkName: "component---src-pages-solicitors-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */)
}

